$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$danger: #dc3545;
$warning: #ffc107;
$info: #ffc107;
$light: #f8f9fa;
$dark: #343a40;
$lt-yellow: #fae8b1;
$gray: #b2b7c1;
$lt-gray: #f5f5f5;
$md-gray: #dedede;

.primary{
    color: $primary;
}
.secondary{
    color: $secondary;
}
.success{
    color: $success;
}
.danger{
    color: $danger;
}
.warning{
    color: $warning;
}
.info{
    color: $info;
}
.light{
    color: $light;
}
.dark{
    color: $dark;
}
.lt-yellow{
    color: $lt-yellow;
}
.gray{
    color: $gray;
}
.lt-gray{
    color: $lt-gray;
}
.md-gray{
    color: $md-gray;
}