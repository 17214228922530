// Definitions
$primary: #70bf44; 
$primary-light: #cdf284; 
$primary-dark: #5c8804; 
$secondary: #3c4b64;
$light: #f7f7f7;
$dark: #333;
$gray: #b2b7c1;
$app-bg-color: #000;
$header-bg-color:#000000F0;
// Reactstrap Colors
$r-primary: #007bff;
$r-secondary: #6c757d;
$r-success: #28a745;
$r-danger: #dc3545;
$r-warning: #ffc107;
$r-info: #ffc107;
$r-light: #f8f9fa;
$r-dark: #343a40;
$r-lt-yellow: #fae8b1;
$r-lt-gray: #f5f5f5;
$r-md-gray: #dedede;

$round-bg-sm: 40px;
$round-bg-md: 80px;
$round-bg-lg: 120px;
$logo_width: 45%;
$logo_height: auto;

/*$overlay-img: "https://prd-mp-images.azureedge.net/4c15e768-62de-413e-a99b-a9bf2f69393a/image/9262df9a-a757-42c3-85e1-1cccb7152c2f/noe-overlay.png";*/
$overlay-img: "https://images.marketpath.com/fd7889fd-dac4-4f8a-87ef-c6ca38ae7aae/image/fb551120-f984-4826-920b-ac37048c5f78/ScaryFastLogoTransparent.png";
$bg-img: "https://prd-mp-images.azureedge.net/4c15e768-62de-413e-a99b-a9bf2f69393a/image/bc6c54b2-a05e-47f5-a6b7-120a57b50529/background.png";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

// General App styling
* {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

h1 {
  font-weight: 400;
  color:$primary !important;
}
h2, h3, h4, h5, h6{
  font-weight: 400;
  /*color:$dark !important;*/
}

hr {
  opacity: .125 !important;
}

.no-scroll{
  overflow: hidden;
}

.hide {
  display: none;
}

.toggle-header-icon {
  cursor: pointer;
}

.pagination-arrow {
  cursor:pointer;
}

.float-right{
  float: right !important;
}

.show-toggle-content{
  .optional-content{
    display: none;
  }

  .toggle-optional-content .col{
    visibility: hidden;
  }

  .toggle-optional-content:hover .col{
    visibility: visible;
  }
}

.show-optional-content{
  .toggle-optional-content{
    display: none;
  }
}

.display-photos-container, .display-photos-container .container {
  max-width:100% !important;
}

.hover .hover-only {
  display:flex;
}

.no-hover .hover-only {
  display:none;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

// Bootstrap Overrides

.container{ // This controls the top margin inside of the main containers
  margin-top: .5rem;
}

.navbar{
  padding-top: 0.05rem !important;
  padding-bottom: 0.05rem !important;
}

.navbar-brand{
  width: 25rem;
  padding-left: .5rem;
}

.modal{
  &-header{
    padding: .5rem 1rem;
  }
  .close{
    background-color: transparent;
    border: none;
    font-size: 2rem;
    padding: 0;
    margin: 0;
  }
}

.no-header .modal-header{display:none;}
.no-footer .modal-footer{display:none;}

.modal-dialog {
  max-width:1350px !important;
}

  .full-screen {
    width:100% !important;
    height:100% !important;
  }

  .full-size-photo{
    max-width:100%;
    max-height:auto;
  }

.form-control{
  border-radius: .5rem !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Custom styles for controls
.tooltip{
  &--left-align{
    text-align: left;
  }
}

.list{
  padding-left: 0;
  list-style: none;
  &--py-small{
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  &--border-t{
    border-top: solid 1px #dfdfdf;
  }
  &--border-b{
    border-bottom: solid 1px #dfdfdf;
  }
}

.dropdown-menu{
  min-width: 20rem !important;
}
.dropdown-item{
  padding: .5rem 1rem !important;
}
.dropdown-menu button:active{
  background-color: transparent;
}

// Utilities
.img-fluid {
  max-height: auto;
  height: 100%;
}
.img-circle {
  border-radius: 50%;
}
.align-right{
  text-align: end;
}
.align-center{
  display: flex;
  align-items: center;
}
.justify-content{
  display: flex;
  &--center{
    align-items: center;
    justify-content: center;
  }
  &--center-top{
    align-items: baseline;
    justify-content: center;
  }
}
.justify-center{
  display: flex;
      justify-content: center !important;
  &-xs{
    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: center !important;
    }
  }
  &-sm{
    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: center !important;
    }
  }
  &-md{
    @media screen and (max-width: 960px) {
      display: flex;
      justify-content: center !important;
    }
  }
}
.centered-div{
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: row;
}
.cursor-pointer{
  cursor: pointer;
}
.align-end{
  text-align: end;
}
.scroll{
  &-x{
    width: 100%;
    // height: 135px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-left: .75rem;
    padding-top: .35rem;
  }
  .card{
    display: inline-block;
    width: 135px;
    margin-right: .5rem;
  }
}
.wrapper{
  width: 100%;
  height: 100px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.wrapper .item{
  display: inline-block;
  width: 150px;
}
.text-large{
  &-1{
    font-size: 1rem;
  }
  &-2{
    font-size: 2rem;
  }
  &-3{
    font-size: 3rem;
  }
  &-4{
    font-size: 4rem;
  }
  &-5{
    font-size: 5rem;
  }
}
.full-height{
  height: 100% !important;
}
.fixed-w-50{
  width: 50px;
}
.fixed-h-50{
  height: 50px;
}
.fixed-w-100{
  width: 100px;
}
.fixed-h-100{
  height: 100px;
}
.dashed-border{
  border: 1px dashed #d6d6d6;
}
.solid-border{
  border: 1px solid #e0e0e0;
}
.m{
  &--sm-0{
    @media screen and (max-width: 576px) {
      margin: 0 !important;
    }
  }
  &--sm-1{
    @media screen and (max-width: 576px) {
      margin: 1rem !important;
    }
  }
  &--md-0{
    @media screen and (max-width: 760px) {
      margin: 0 !important;
    }
  }
  &--md-1{
    @media screen and (max-width: 760px) {
      margin: 1rem !important;
    }
  }
  &--lg-0{
    @media screen and (min-width: 761px) {
      margin: 0 !important;
    }
  }
  &--lg-1{
    @media screen and (min-width: 761px) {
      margin: 0 1rem !important;
    }
  }
}

.h-90{
  max-height: 90vh !important;
}

.w-90{
  max-width: 90vw !important;
}

.h-80{
  max-height: 80vh !important;
}

.w-80{
  max-width: 80vw !important;
}

.grid-photo{
  
}

.dashboard{
  h4{ 
    font-size: 22px !important;
  }
  .card{
    height: 100% !important;
    &-header{
      background: transparent;
      border-bottom: none;
    }
    &--logo{
      img{
        max-width: 90px;
        height: auto;
        padding-right: 1rem;
      }
    }
    &__description{
      text-align: left !important;
    }
    &__location{
      border: none;
      
      .card_logo{
        width:100%;
        max-width:100px;
      }
      .card-body{
        padding: .5rem 0;
        height: 55px;
      }
      .card-footer{
        background: transparent;
        border-top: none;
        padding: .25rem 0;
      }
      .address-1, .address-2, .address-city-state{
        font-size: .85rem !important;
        font-weight: 500;
      }
    }
    img{
      max-width: 200px;
      height: auto;
      @media screen and (max-width: 768px) {
        max-width: 185px;
        height: auto;
      }
      @media screen and (max-width: 576px) {
        max-width: 105px;
        height: auto;
      }
    }
    @media screen and (max-width: 768px) {
      h4{ 
        font-size: 20px !important;
      }
      .card{
        img{
          max-width: auto;
          max-height: 80px;
        }
      }
      
    }
  }
}

.upload-picture{
  img{
    max-width: 150px;
    height: auto;
  }
}

.md-icon {
  font-size:1.5rem;
}

.location{
  &-qr-img{
    width: 120px;
    height: 120px;
  }
  .card{
    .card_logo{
      width:100%;
      max-width:80px;
    }
    .card-body{
      padding: .5rem 0;
      height: 55px;
    }
    .card-footer{
      background: transparent;
      border-top: none;
      padding: .25rem 0;
    }
    .address-1, .address-2, .address-city-state{
      font-size: .85rem !important;
      font-weight: 500;
    }
  }
}

.location-icons {
  .location-icon{
    font-size:1.1rem;
  }
}
.location-header {
  min-height:5rem;
  margin-bottom: .5rem;

	.location-top-row {
    h1, h2, h3, h4, h5, h6{
      margin-bottom: 0;
    }
	}

	&__name {
    font-style:italic;
	}

	.location-detail-row {
    line-height: 80%;
	}

	.location-logo-container img {
    max-width: 100px;
    height: auto;
	}

	.location-address-container {
    font-size:.75rem;
    margin-left: 0;
    padding-left: 0; 
    @media screen and (max-width: 576px) {
      padding-left: .5rem;
    } 
	}

	.location-map-container {
    cursor: pointer;
  }
  
  .location-map-container .map-label{
    padding-left:.06125rem;
    text-transform: uppercase;
  }

	.map-icon {
    font-size:1.5rem;
	}

	.map-label {
    font-size:.7rem;
    font-weight:bold;
	}
}

.green-check {
  color:green;
}

.location-header .phone-number, .location.address{
  line-height: 1.25rem;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.location-header-logo {
  width:100%;
  max-width:100px;
  border-radius: 50%;
    padding: 1px;
    border: 2px solid rgb(215, 215, 215);
}

.back-stripe .back-link-container {
  background-color:#fff;
}

.cart-item > .row{
  margin: 1rem;
  background-color: #99999950;
  padding: 1rem;
  border-radius: .5rem;
}

.cart-item select{
  float: right;
  width:50%;
}
.cart-item label{
  float:left;
}
.cart-item .data-cell{
  margin-bottom:.5rem;
}

input[type="checkbox"] {
  transform:scale(2, 2);
}

.screen-blocker {
  width:100%;
  height: 100%;
  position: absolute;
  left:0;
  top:0;
  background-color: rgba(0, 0, 0, .9);

  &-text{
    text-align: center;
    padding:10%;
    color:#fff;
  }
}

.main-action-icon{
  color:#000 !important;
  float: right;
}

.half-width-container{
  max-width: 55rem !important;
}

.spin {  
  -webkit-animation:spin 3s linear infinite;
  -moz-animation:spin 3s linear infinite;
  animation:spin 3s linear infinite;
}

.manual-email-entry{
  width: 100%;
  height: 75px;
  font-size: 1.25rem;
}

.photo-tile-fields {
  .col {
    text-align: left;
  }

  select {
    width:100%;
  }

  label {
    float:left;
  }

  .row{
    margin-bottom:.5rem;
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }